<template>
  <account-detail
    :show-action-buttons="false"
    user-detail-link="user-detail-ext"
  />
</template>
<script>
import AccountDetail from '@/views/account/detail/AccountDetail.vue'

export default {
  components: { AccountDetail },
}
</script>
